import React from 'react'

// necessary set
import { NavLink } from 'react-router-dom'

// import React from 'react'
import { observer } from 'mobx-react'

// need content
// Props type
import { IPageProps } from '@common/PropsType'
import { NavMenuList } from './menu'

// need style
import '@pages/layout/footer.scss'

interface Props extends IPageProps {
  goTop: () => void
}

/**
 * @author cardo
 * @description
 * sto-user-front: footer
 * 기본 footer
 */
const Footer: React.FC<Props> = observer((props) => {
  const date = new Date().getTime()
  const getMenuList = () => {
    return (
      <ul className="footer-link">
        <li>
          <NavLink to="/" onClick={props.goTop}>
            Home
          </NavLink>
        </li>
        {NavMenuList.map((menu) => {
          const isExternal = menu.type === 'btn' || menu.target === '_blank'
          return (
            <li key={menu.url}>
              <NavLink
                to={menu.url}
                onClick={isExternal ? undefined : props.goTop}
                rel={isExternal ? 'noreferrer' : undefined}
                target={isExternal ? '_blank' : undefined}
              >
                {menu.title}
              </NavLink>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <footer>
      <div className="footer-content">
        <div className="footer-split">
          <div className="company-content">
            <div className="footer-img">
              <span className="isms-logo"></span>
              <span className="soc-logo"></span>
            </div>
            <div className="company-info">
              <span className="tit">상호명</span>
              <span className="txt">(주) 한국디지털자산수탁</span>
            </div>
            <div className="company-info">
              <span className="tit">주소</span>
              <span className="txt">(06536) 서울시 서초구 강남대로 525, 4층 (반포동, 세영제이타워)</span>
            </div>
            <div className="company-info">
              <span className="tit">사업자 등록번호</span>
              <span className="txt">809-86-01583</span>
            </div>
          </div>
          <div className="link-box">
            {getMenuList()}
            <ul className="customer-link">
              <li>고객지원</li>
              <li>
                <NavLink to={`https://static.kdac.io/service/html-docs/investment.htm?${date}`} target="_blank">
                  가상자산 투자/수탁 유의사항
                </NavLink>
              </li>
              <li>
                <NavLink to={`https://static.kdac.io/service/html-docs/video.htm?${date}`} target="_blank">
                  영상정보처리기기 운영·관리방침
                </NavLink>
              </li>
              <li>
                <NavLink to={`https://static.kdac.io/service/service_latest.pdf?${date}`} target="_blank">
                  서비스 이용 약관
                </NavLink>
              </li>
              <li className={'privacy'}>
                <NavLink to={`https://static.kdac.io/service/html-docs/privacy-policy.htm?${date}`} target="_blank">
                  개인정보 처리방침
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="reserved">© Korea Digital Asset Custody Co., Ltd.</div>
      </div>
    </footer>
  )
})

export default Footer
